<template>
    <section class="faqs">
        <tool-bar v-bind:lang.sync="lang">
            Frequently Asked Questions
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-on:click="addFAQSection">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add FAQ Section</span>
                    </button>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <draggable v-model="faqs">
            <div class="container py-5 faqs my-5"
                 v-for="(section, index) in faqs"
                 v-bind:key="index">
                <h3 class="text-center mb-5 editable">
                    <input class="input"
                           type="text"
                           placeholder="Section Title"
                           v-model="section.title[lang]">
                </h3>
                <div class="d-flex justify-content-end align-items-center mb-3">
                    <button class="button"
                            v-on:click="addFAQ(section.faq)">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add FAQ Block</span>
                    </button>
                </div>
                <draggable v-model="section.faq">
                    <div class="card card-faq mb-4"
                         v-for="(faq, index) in section.faq"
                         v-bind:key="index">
                        <header class="card-headercard-faq-header p-0">
                            <div class="columns">
                                <div class="column is-1 d-flex justify-content-end align-items-center">
                                    <h4 class="h4 font-weight-light mb-0">Q: </h4>
                                </div>
                                <div class="column">
                                    <h4 class="control font-weight-light mb-0">
                                        <input class="input"
                                               type="text"
                                               placeholder="Question"
                                               v-model="faq.question[lang]">
                                    </h4>
                                </div>
                            </div>
                        </header>
                        <div class="card-body pr-0">
                            <div class="columns">
                                <div class="column is-1"></div>
                                <div class="column">
                                    <p class="card-text faq-answer">
                                        <textarea class="textarea"
                                                  placeholder="Answer"
                                                  rows="3"
                                                  v-model="faq.answer[lang]">
                                        </textarea>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end align-items-center mb-3">
                            <button class="button is-danger is-outlined"
                                    v-on:click="removeFAQ(section.faq, index)">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                </span>
                                <span>Remove Q&A pair</span>
                            </button>
                        </div>
                    </div>
                </draggable>
                <div class="d-flex justify-content-end align-items-center mb-3">
                    <button class="button is-danger is-outlined"
                            v-on:click="removeFAQSection(index)">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                </span>
                        <span>Remove FAQ Section</span>
                    </button>
                </div>
            </div>
        </draggable>
    </section>
</template>

<script>
import draggable from 'vuedraggable'
import ToolBar from '../layouts/ToolBar'

export default {
    name: 'FAQ',
    components: {
        draggable,
        ToolBar
    },
    data() {
        return {
            loading: false,
            lang: 'en',

            faqs: []
        }
    },
    computed: {
        allowSave() {
            return this.faqs.reduce(
                (acc, section) => {
                    return acc &&
                        section.title['en'] &&
                        section.title['zh-hk'] &&
                        section.title['zh-cn'] &&
                        section.faq &&
                        Array.isArray(section.faq) &&
                        section.faq.length > 0 &&
                        section.faq.reduce(
                            (acc, faq) => {
                                return acc &&
                                    faq.question &&
                                    faq.answer
                            }, true
                        )
                }, true
            )
        }
    },
    methods: {
        addFAQSection() {
            this.faqs.push({
                title: {
                    'en': '',
                    'zh-hk': '',
                    'zh-cn': ''
                },
                faq: [{
                    question: {
                        'en': '',
                        'zh-hk': '',
                        'zh-cn': ''
                    },
                    answer: {
                        'en': '',
                        'zh-hk': '',
                        'zh-cn': ''
                    }
                }]
            })
        },

        removeFAQSection(index) {
            this.faqs.splice(index, 1)
        },

        addFAQ(faq) {
            faq.push({
                question: {
                    'en': '',
                    'zh-hk': '',
                    'zh-cn': ''
                },
                answer: {
                    'en': '',
                    'zh-hk': '',
                    'zh-cn': ''
                }
            })
        },

        removeFAQ(faq, index) {
            faq.splice(index, 1)
        },

        async save() {
            this.loading = true
            try {
                await this.$store.dispatch('salon/updateFAQs', this.faqs)
            } catch (e) {
                console.log(e)
                this.loading = false
                return
            }
            this.loading = false
        }
    },
    mounted() {
        this.faqs = this.$store.state.salon.salon.faqs
    },
    watch: {
        '$store.state.salon.salon.faqs'(newVal) {
            if (newVal) {
                this.faqs = newVal
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.editable {
    position: relative;
    border-bottom: dashed 2px #8c67ef;

    &:after {
        position: absolute;
        bottom: -24px;
        right: 0;
        content: "Click to edit";
        font-size: 14px;
        font-weight: normal;
        padding: 3px;
    }

    & > input {
        border: none;
        box-shadow: none;
        text-align: center;
    }
}

.faqs {
    color: rgba(113, 113, 113, 1);

    .container {
        background-color: rgba(255, 255, 255, 1);

        .card-faq {
            border: none;
            border-bottom: solid 1px rgba(0, 0, 0, 0.125);
            box-shadow: none;
            border-radius: 0;

            .card-faq-header {
                border: none;
            }

            .faq-answer {
                color: rgba(168, 128, 67, 1);
            }
        }
    }
}
</style>
